import { i18n } from "@bebit/store";
import { ModuleMetadata } from "@sentry/core";
import * as Sentry from "@sentry/vue";
import { createApp } from "vue";
import App from "@host/App.vue";
import env from "@host/env";
import router from "@host/router";
import "@host/assets/scss/app.scss";
import { globalErrorStore } from "@host/store/error";
import { handle403 } from "@host/utils/error";

const app = createApp(App);
app.use(i18n);

const getEnvironmentForSentry = (hostname: string) => {
  switch (hostname) {
    case "app.usergram.info":
      return "production";
    case "staging-app.bebit-dev.com":
      return "staging";
    case "localhost":
    case "127.0.0.1":
      return "local";
    default:
      return "development";
  }
};

const environment = getEnvironmentForSentry(location.hostname);
if (environment !== "local") {
  Sentry.init({
    app,
    dsn: env.sentryDsn,
    environment,
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: ["app.usergram.info", /.*.bebit-dev.com/],
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new ModuleMetadata(),
    ],
    tracesSampleRate: 1.0,
  });
}

app.use(router);

// See also: https://vuejs.org/api/application.html#app-config-errorhandler
app.config.errorHandler = function (err: unknown) {
  if (environment === "local") console.error(err);

  const { showError } = globalErrorStore();

  if (err instanceof Error && err.name === "CanceledError") return;

  if (err instanceof RangeError) {
    showError(err.message);
    return;
  }

  if (err instanceof Error && err.name === "UgError" && err.message) {
    showError(err.message);
    return;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if ((err as any).response?.status === 403) {
    handle403(err);
    return;
  }
  Sentry.captureException(err);

  showError();
};

app.provide("ugTagMasterId", env.ugTagMasterId);
app.mount("#app");
