import { globalErrorEventBusService } from "@bebit/event-bus";
import { i18n } from "@bebit/store";

// eslint-disable-next-line max-lines-per-function, @typescript-eslint/no-explicit-any
export function getApiErrorMessage(errorData: any): string {
  // Respond to the following three error response types:
  // ▼ Error response created by the authentication unit by itself
  // {"success":boolean,"errors":{field_name:[errorMessage]}}
  //
  // ▼ Error response created by the authentication unit when making a request such as bulk INSERT
  // {"success":boolean,"errors":{[field_name:[errorMessage]}]}
  //
  // ▼ error-response received by server-side
  // {"detail": errorMessage}

  const hasFieldError = errorData && errorData.errors;
  const hasErrorDetail = errorData && errorData.detail;
  if (hasFieldError) {
    const errorMessages = errorData.errors;
    // For an array of {field_name:[errorMsg]}
    if (Array.isArray(errorMessages)) {
      for (const errorMsg of errorMessages) {
        Object.keys(errorMsg).forEach((fieldName) => {
          if (errorMsg[fieldName] !== undefined) {
            return errorMsg[fieldName][0];
          }
        });
      }
    }
    //
    // For a single {fieldName:[errorMsg]}
    for (const fieldName in errorMessages) {
      if (errorMessages[fieldName] !== undefined) {
        return errorMessages[fieldName][0];
      }
    }
  } else if (hasErrorDetail) {
    return errorData.detail;
  }
  // If none match, return a generic error message
  return i18n.global.t("utils.host.genericErrorMessage");
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function handle403(error: any) {
  const errMsg: string | undefined = error.response?.data?.detail;
  if (errMsg?.toLowerCase().startsWith("csrf failed:")) {
    globalErrorEventBusService.send(i18n.global.t("utils.host.csrfErrorMessage"));
    window.setTimeout(() => location.reload(), 3000);
  } else if (location.pathname !== "/login") {
    globalErrorEventBusService.send(i18n.global.t("utils.host.redirectToLoginErrorMessage"));
    window.setTimeout(() => location.assign("/login"), 3000);
  } else {
    globalErrorEventBusService.send(getApiErrorMessage(error.response?.data));
  }
}
