<template>
  <div>
    <div v-if="isRouteLoading" class="app__loading">
      <UgLoading height="80vh" />
    </div>
    <component :is="layout" v-else-if="authStore.user" />
    <AlertDialog :model-value="hasError" :message="message" @update:model-value="clearError" />
    <AlertDialog
      :model-value="shouldShowWarningModal"
      :message="warningMessage"
      data-cy="global-warning-modal"
      @update:model-value="clearWarning"
    />
  </div>
</template>

<script setup lang="ts">
import { UgLoading, AlertDialog } from "@bebit/atomic";
import { authStore } from "@bebit/auth";
import { globalErrorEventBusService, globalWarningEventBusService } from "@bebit/event-bus";
import { computed, onMounted, defineAsyncComponent } from "vue";
import { useRoute } from "vue-router";
import { appStore } from "@host/store/app";
import { globalErrorStore } from "@host/store/error";
import { globalWarningStore } from "@host/store/warning";
import { Layout } from "@host/types/router/router.type";

// Load layout lazily to avoid loading layout when redirecting to login
const DefaultLayout = defineAsyncComponent(() => import("@host/layouts/DefaultLayout.vue"));
const IntermediateLayout = defineAsyncComponent(() => import("@host/layouts/IntermediateLayout.vue"));
const ReportLayout = defineAsyncComponent(() => import("@host/layouts/ReportLayout.vue"));
const ReportSettingsLayout = defineAsyncComponent(() => import("@host/layouts/ReportSettingsLayout.vue"));

const { isRouteLoading } = appStore();
const { message, hasError, clearError, showError } = globalErrorStore();
const { message: warningMessage, isActive: shouldShowWarningModal, clearWarning, showWarning } = globalWarningStore();

const route = useRoute();

const layout = computed(() => {
  switch (route.meta.layout) {
    case Layout.Report:
      return ReportLayout;
    case Layout.ReportSettings:
      return ReportSettingsLayout;
    case Layout.Intermediate:
      return IntermediateLayout;
    default:
      return DefaultLayout;
  }
});

onMounted(() => {
  globalErrorEventBusService.subscribe((event: Event) => {
    showError((event as CustomEvent<string>).detail);
  });
  globalWarningEventBusService.subscribe((event: Event) => {
    showWarning((event as CustomEvent<string>).detail);
  });
});
</script>

<style lang="scss" scoped>
.app__loading {
  width: 100vw;
}
</style>
