import { authStore } from "@bebit/auth";
import {
  Error403,
  ErrorMfaNotEnabled,
  ErrorMfaNotVerified,
  ErrorNotAllowedBeforeLogin,
  ErrorNotAllowedBeforeLoginSilentRedirect,
} from "@bebit/errors";
import { i18n, useUgTag, routeStore } from "@bebit/store";
import { LAG_CV_PERIODS } from "@bebit/types";
import * as Sentry from "@sentry/vue";
import Vue from "vue";
import { NavigationFailureType, createRouter, createWebHistory, isNavigationFailure } from "vue-router";
import env from "@host/env";
import { appStore } from "@host/store/app";
import { globalErrorStore } from "@host/store/error";
import { Layout } from "@host/types/router/router.type";
import { navigationGuards } from "./navigation.guards";
import type { NavigationFailure, RouteRecordRaw, RouteLocationRaw } from "vue-router";

const { pushPv } = useUgTag(env.ugTagMasterId);
const { allowRouterReplace, setAllowRouterReplace } = routeStore();

export const routes: RouteRecordRaw[] = [
  {
    path: "/v3/memo-users", // TODO: change to `/` when exposing to users
    name: "MemoUsers",
    // eslint-disable-next-line import/no-unresolved
    component: () => import("search/MemoUsersPage"),
    meta: {
      title: "MemoUsers",
      useNewSideBar: true,
    },
  },
  {
    path: "/watch-history",
    name: "WatchHistory",
    // eslint-disable-next-line import/no-unresolved
    component: () => import("search/WatchHistoryPage"),
    meta: {
      title: "WatchHistory",
    },
  },
  {
    path: "/v3/watch-history", // required for integration-test to use cy.visit("/watch-history");
    redirect: { name: "WatchHistory" },
  },
  {
    path: "/",
    name: "Home",
    component: () => import("@host/views/HomePage.vue"),
    meta: {
      title: "Home",
    },
  },
  {
    path: "/stats",
    name: "Stats",
    component: () => import("@host/views/StatsPage.vue"),
    meta: {
      layout: Layout.Report,
      icon: "analysis",
      title: "Stats",
    },
  },
  {
    path: "/v3/stats",
    redirect: { name: "Stats" },
  },
  {
    path: "/journey",
    name: "Journey",
    component: () => import("@host/views/JourneyPage.vue"),
    meta: {
      layout: Layout.Report,
      icon: "branches",
      title: "Journey",
    },
  },
  {
    path: "/v3/journey",
    redirect: { name: "Journey" },
  },
  {
    path: "/intermediate-journey",
    name: "IntermediateJourney",
    component: () => import("@host/views/IntermediateJourneyPage.vue"),
    meta: {
      layout: Layout.Intermediate,
    },
  },
  {
    path: "/v3/intermediate-journey",
    redirect: { name: "IntermediateJourney" },
  },
  {
    path: "/client-settings/behavior-labels/pv",
    name: "BehaviorLabelsSettings",
    component: () => import("@host/views/BehaviorLabelsSettingsPage.vue"),
    meta: {
      layout: Layout.ReportSettings,
      icon: "branches",
      title: "BehaviorLabelsSettings",
      activeRouteName: "Journey",
    },
  },
  {
    path: "/v3/client-settings/behavior-labels/pv",
    redirect: { name: "BehaviorLabelsSettings" },
  },
  {
    path: "/users",
    name: "SearchResult",
    // eslint-disable-next-line import/no-unresolved
    component: () => import("search/SearchResultPage"),
  },
  {
    path: "/users/:id",
    redirect: (to) => {
      return {
        name: "SearchResult",
        query: {
          ...to.query,
          uid: to.params.id,
        },
      };
    },
  },
  {
    path: "/v3/users/:id",
    redirect: (to) => {
      return {
        name: "SearchResult",
        query: {
          ...to.query,
          uid: to.params.id,
        },
      };
    },
  },
  {
    path: "/v3/users",
    redirect: { name: "SearchResult" },
  },
  {
    path: "/bookmark-users",
    name: "bookmark-users",
    component: () => import("search/BookmarkUsers.vue"),
  },
  {
    path: "/lagcv",
    name: "LagCv",
    component: () => import("@host/views/LagCvUsersPage.vue"),
    children: [
      {
        path: `:period(${LAG_CV_PERIODS.join("|")})`,
        name: "LagCvByPeriod",
        component: () => import("@host/views/LagCvUsersPage.vue"),
      },
      {
        path: `:period(${LAG_CV_PERIODS.join("|")})/:conversionId`,
        name: "LagCvByCv",
        component: () => import("@host/views/LagCvUsersPage.vue"),
      },
    ],
  },
  {
    path: "/v3/lagcv",
    redirect: { name: "LagCv" },
    children: [
      {
        path: `:period(${LAG_CV_PERIODS.join("|")})`,
        redirect: { name: "LagCvByPeriod" },
      },
      {
        path: `:period(${LAG_CV_PERIODS.join("|")})/:conversionId`,
        redirect: { name: "LagCvByCv" },
      },
    ],
  },
  {
    path: "/almost-cv/:conversionId",
    name: "AlmostCv",
    component: () => import("@host/views/AlmostCvUsersPage.vue"),
  },
  {
    path: "/v3/almost-cv/:conversionId", // required for integration-test
    redirect: { name: "AlmostCv" },
  },
  {
    path: "/v3",
    redirect: { name: "Home" },
  },
  {
    path: "/funnel",
    name: "funnel-analysis-home",
    component: () => import("funnel/FunnelHome.vue"),
  },
  {
    path: "/v3/funnel", // required for integration-test
    redirect: { name: "funnel-analysis-home" },
  },
  {
    path: "/funnel/create",
    name: "funnel-analysis-create",
    component: () => import("funnel/FunnelCreate.vue"),
  },
  {
    path: "/v3/funnel/create", // required for integration-test
    redirect: { name: "funnel-analysis-create" },
  },
  {
    path: "/funnel/:id",
    name: "funnel-analysis-detail",
    component: () => import("funnel/FunnelResult.vue"),
  },
  {
    path: "/v3/funnel/:id", // required for integration-test
    redirect: { name: "funnel-analysis-detail" },
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: { name: "Home" },
  },
];

export const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }

    // Disabling same page transition e.g. router.replace({ query })
    if (from.matched.length > 0 && to.matched[0].path !== from.matched[0].path) {
      return { top: 0 };
    }
  },
});

const { setIsRouteLoading } = appStore();

router.beforeEach(async (_to, _from) => {
  if (_to.path !== _from.path) setIsRouteLoading(true);
  await navigationGuards(_to);
});

router.onError((error) => {
  if (error instanceof ErrorNotAllowedBeforeLoginSilentRedirect) {
    redirectToLoginPage();
    return;
  }

  const { showError } = globalErrorStore();
  Sentry.captureException(error.message);
  showError(error.message);

  if (error instanceof ErrorNotAllowedBeforeLogin) {
    redirectToLoginPage();
  } else if (error instanceof ErrorMfaNotEnabled) {
    redirectToMfaSetupPage();
  } else if (error instanceof ErrorMfaNotVerified) {
    redirectToMfaTotpPage();
  } else if (error instanceof Error403) {
    redirectToUgHomePage();
  }
});

function redirectToUgHomePage() {
  router.push({ name: "Home" });
}

function redirectToMfaSetupPage() {
  window.location.assign("/mfa");
}

function redirectToMfaTotpPage() {
  window.location.assign("/totp");
}

function redirectToLoginPage() {
  const redirectTo =
    window.location.pathname === "/" ? "/login" : `/login?redirect=${encodeURIComponent(window.location.pathname)}`;

  window.location.assign(redirectTo);
}

router.afterEach((to, from, failure?: NavigationFailure | void) => {
  if (to.meta?.title) to.meta.title = getPageTitle(to.meta.title as string);

  if (!isNavigationFailure(failure, NavigationFailureType.aborted)) {
    setIsRouteLoading(false);
    if (from.name === null || to.path !== from.path) {
      let pageTitle: string = getPageTitle(to.name as string);
      if (isLagCvRoute(to.path)) {
        pageTitle = getLagCvPageTitle(to.params.period as string);
      }

      Vue.nextTick(() => {
        pushPv(authStore.user?.loginName);
        document.title = to.name ? `${pageTitle} | USERGRAM` : "USERGRAM";
      });
    }
  }
  setAllowRouterReplace(true);
});

configureRouterReplaceToBeStopable();

export default router;

function getPageTitle(key: string): string {
  return i18n.global.t(`pageTitle.${key}`);
}

function getLagCvPageTitle(period: string): string {
  return i18n.global.t(`pageTitle.${period}`) as string;
}

function isLagCvRoute(path: string): boolean {
  return path.startsWith("/lagcv");
}

function configureRouterReplaceToBeStopable() {
  const originalReplace = router.replace;
  router.replace = async function (to: RouteLocationRaw) {
    if (allowRouterReplace.value) {
      return originalReplace.call(this, to);
    }
  };
}
