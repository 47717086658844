import { i18n, useAlertDialog } from "@bebit/store";
import { createGlobalState } from "@vueuse/core";
import { ref, computed } from "vue";

const GENERIC_ERROR_MESSAGE = i18n.global.t("utils.host.genericErrorMessage");

export const globalErrorStore = createGlobalState(() => {
  const message = ref("");
  const hasError = computed(() => message.value !== "");
  const { openDialog, closeDialog } = useAlertDialog();

  async function clearError() {
    closeDialog();
    message.value = "";
  }

  async function setErrorMessage(errorMessage: string) {
    message.value = errorMessage;
  }

  function showError(errorMessage: string = GENERIC_ERROR_MESSAGE) {
    // FIXME: This function is temporary.
    openDialog();
    setErrorMessage(errorMessage);
  }

  return { message, hasError, clearError, setErrorMessage, showError };
});
