import { authStore, clientStore, configStore, mfaStore, useUserPermissions } from "@bebit/auth";
import {
  Error403,
  ErrorMfaNotEnabled,
  ErrorMfaNotVerified,
  ErrorNotAllowedBeforeLogin,
  ErrorNotAllowedBeforeLoginSilentRedirect,
} from "@bebit/errors";
import { Languages, i18n } from "@bebit/store";
import type { NavigationGuard, RouteLocationNormalized } from "vue-router";

export async function navigationGuards(to: RouteLocationNormalized): Promise<ReturnType<NavigationGuard>> {
  if (!authStore.isInitialized) await authStore.initialize();
  await userNavigationGuard(to);
  if (!clientStore.isInitialized) await clientStore.initialize();
  if (!configStore.isInitialized) await configStore.initialize();
  i18n.global.locale.value = authStore.user?.language as Languages;
  await intermediateJourneyNavigationGuard(to);
  await journeyNavigationGuard(to);
  await statsNavigationGuard(to);
  await behaviorLabelsSettingsNavigationGuard(to);
  await funnelAnalysisNavigationGuard(to);
}

export async function userNavigationGuard(to: RouteLocationNormalized) {
  if (!authStore.user) {
    if (to.path === "/") {
      throw new ErrorNotAllowedBeforeLoginSilentRedirect();
    } else {
      throw new ErrorNotAllowedBeforeLogin();
    }
  }

  // MFA
  await verifySessionWhenMfaIsEnabled();
  await redirectMfa();

  if (authStore.user && authStore.user.isSuperUser) {
    location.assign("/client-settings/");
    return;
  }
}

async function intermediateJourneyNavigationGuard(to: RouteLocationNormalized) {
  const { hasIntermediateJourneyAnalysisPermission } = useUserPermissions();
  if (to.path.includes("/intermediate-journey")) {
    if (!hasIntermediateJourneyAnalysisPermission.value) {
      throw new Error403();
    }
  }
}

async function journeyNavigationGuard(to: RouteLocationNormalized) {
  const { hasJourneyAnalysisPermission } = useUserPermissions();
  if (to.path.includes("/journey")) {
    if (!hasJourneyAnalysisPermission.value) {
      throw new Error403();
    }
  }
}

async function statsNavigationGuard(to: RouteLocationNormalized) {
  const { hasStatsPermission } = useUserPermissions();
  if (to.path.includes("/stats")) {
    if (!hasStatsPermission.value) {
      throw new Error403();
    }
  }
}

async function behaviorLabelsSettingsNavigationGuard(to: RouteLocationNormalized) {
  const { hasJourneyAnalysisPermission } = useUserPermissions();
  if (to.path.includes("/client-settings/behavior-labels")) {
    if (!hasJourneyAnalysisPermission.value || !authStore.user || !authStore.user.isAdmin) {
      throw new Error403();
    }
  }
}

async function funnelAnalysisNavigationGuard(to: RouteLocationNormalized) {
  const { hasFunnelAnalysisPermission } = useUserPermissions();
  const funnelPaths = ["funnel-analysis-home", "funnel-analysis-create", "funnel-analysis-detail"];

  if (to.name && funnelPaths.includes(to.name as string)) {
    if (!hasFunnelAnalysisPermission.value) {
      throw new Error403();
    }
  }
}

async function verifySessionWhenMfaIsEnabled() {
  if (authStore.isMfaRequired && authStore.isMfaEnabled) {
    await mfaStore.verify("");
  }
}

async function redirectMfa() {
  if (authStore.isMfaRequired && !authStore.isMfaEnabled) {
    throw new ErrorMfaNotEnabled();
  }

  if (authStore.isMfaEnabled && !mfaStore.isVerified) {
    throw new ErrorMfaNotVerified();
  }
}
