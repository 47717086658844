import { createGlobalState } from "@vueuse/core";
import { ref } from "vue";
import type { Ref } from "vue";

export const appStore = createGlobalState(() => {
  const isRouteLoading: Ref<boolean> = ref(true);

  function setIsRouteLoading(value: boolean) {
    isRouteLoading.value = value;
  }

  return { isRouteLoading, setIsRouteLoading };
});
