import { createGlobalState } from "@vueuse/core";
import { ref, computed } from "vue";

export const globalWarningStore = createGlobalState(() => {
  const message = ref("");
  const isActive = computed(() => message.value !== "");

  async function clearWarning() {
    message.value = "";
  }
  function showWarning(text: string) {
    message.value = text;
  }

  return { message, isActive, showWarning, clearWarning };
});
