export class Error403 extends Error {
  public name = "403";
  public message = "Not allowed to access this page";
}

export class ErrorMfaNotVerified extends Error {
  public name = "ErrorMfaNotVerified";
  public message = "Please input MFA token";
}

export class ErrorMfaNotEnabled extends Error {
  public name = "ErrorNotAllowedBeforeLogin";
  public message = "Please enable MFA";
}

export class ErrorNotAllowedBeforeLogin extends Error {
  public name = "ErrorNotAllowedBeforeLogin";
  public message = "Not allowed before login";
}

export class ErrorNotAllowedBeforeLoginSilentRedirect extends Error {
  public name = "ErrorNotAllowedBeforeLoginSilentRedirect";
  public message = "Not yet logged in, redirecting to login-page.";
}

export class UgError extends Error {
  public name = "UgError";
}
